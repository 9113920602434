.canvasupload {
  height: 175px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  position: relative;
}
.canvasupload--disabled {
  border: 0;
  height: auto;
}
.canvasupload__img {
  width: 70%;
}
.canvasupload__input {
  height: 100%;
  width: 100%;
}
.canvasupload__value {
  height: 100%;
  width: 100%;
}
.canvasupload__clear {
  position: absolute;
  right: 0;
  top: 0;
  background: black;
  border-radius: 100%;
  color: white;
  font-size: 16px;
  font-weidht: bold;
  height: 26px;
  line-height: 26px;
  text-align: center;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.canvasupload__clearicon {
  position: relative;
  top: -2px;
}