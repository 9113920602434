.p-login {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.p-login input {
  width: 300px;
}
.p-login h1 {
  margin-top: 0;
}
.p-login .wh-form__button {
  width: 100%;
}
.p-login #loginform {
  width: 100%;
  max-width: 600px;
}